export default [
  {
    path: '/distribution/distributionGrade/index',
    name: 'DistributionGrade',
    component: () => import('@/views/distribution/distributionGrade'),
    meta: { title: '分销等级', keepAlive: true },
  },
  {
    path: '/distribution/distributionGrade/index/addLevel',
    name: 'AddLevel',
    component: () => import('@/views/distribution/distributionGrade/details/addLevel.vue'),
    meta: { title: '添加等级' },
  },
]
