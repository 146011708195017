const state = {
  distLevelListInfo: null
}
const mutations = {
  DIST_LEVEL_LIST(state, data) {
    state.distLevelListInfo = data
  },
}

const actions = {
  distLevelListInfo({ commit }, data) {
    console.log(data);

    commit('DIST_LEVEL_LIST', data)
  },
}


export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
