import distributionSet from './distributionSet'
import distributorAdmin from './distributorAdmin'
import distributionGrade from './distributionGrade'
import distributionWaybill from './distributionWaybill'
import distributionOrder from './distributionOrder'

export default [
  ...distributionSet,
  ...distributorAdmin,
  ...distributionGrade,
  ...distributionWaybill,
  ...distributionOrder,
]
