import Vue from 'vue'
import VueRouter from 'vue-router'
import MainLayout from '@/views/main/layout/MainLayout'
import Login from '@/views/main/Login'
import Page404 from '@/views/main/404'
import ChangePsw from '@/views/main/ChangePsw'
import main from './main/main'
import staffCenter from './staffCenter'
import oaManagement from './oaManagement'
import config from './config'
import configure from './configure'
import userOperation from './userOperation'
import driverOperation from './driverOperation'
import userCenter from './userCenter'
import market from './market'
import finance from './finance'
import messageManagement from './messageManagement'
import contentManagement from './contentManagement'
import packageManagement from './packageManagement'
import enterpriseOperations from './enterpriseOperations'
import riskControlCenter from './riskControlCenter'
import networkFreight from './networkFreight'
import distribution from './distribution'

// 解决vue-router在3.0版本以上重复报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

Vue.use(VueRouter)

// 把模块路由添加到这里
const moduleRoutes = [
  ...main,
  ...staffCenter,
  ...oaManagement,
  ...config,
  ...configure,
  ...userOperation,
  ...driverOperation,
  ...userCenter,
  ...market,
  ...finance,
  ...messageManagement,
  ...contentManagement,
  ...packageManagement,
  ...enterpriseOperations,
  ...riskControlCenter,
  ...networkFreight,
  ...distribution,
]

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/change-password',
    name: 'changePsw',
    component: ChangePsw,
  },
  {
    path: '/',
    name: 'layout',
    component: MainLayout,
    redirect: '/home',
    children: moduleRoutes,
  },
  {
    path: '*',
    name: 'Page404',
    component: Page404,
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
