export default [
  {
    path: '/user-center/driver-manage/driverList',
    name: 'driverList',
    component: () => import('@/views/userCenter/driverManage/driverList/list'),
    meta: { title: '司机列表', keepAlive: true },
  },
  // 司机列表详情
  {
    path: '/user-center/driver-manage/driverList/driverDetails',
    name: 'driverDetails',
    hidden: true,
    component: () =>
      import('@/views/userCenter/driverManage/driverList/detail/driverDetails'),
    meta: { title: '司机详情', keepAlive: true },
  },
  // 证件更新
  {
    path: '/user-center/driver-manage/driverList/certificate-renewal',
    name: 'driverDetails',
    hidden: true,
    component: () =>
      import(
        '@/views/userCenter/driverManage/driverList/components/infoUpdateRecord/detail/certificateRenewal'
      ),
  },

  {
    path: '/user-center/driver-manage/taskList',
    name: 'TaskList',
    component: () => import('@/views/userCenter/driverManage/taskList/list'),
  },
  {
    path: '/user-center/driver-manage/taskList/theOrderDetails',
    name: 'theOrderDetails',
    hidden: true,
    component: () =>
      import('@/views/userCenter/driverManage/taskList/detail/theOrderDetails'),
  },
  {
    path: '/user-center/driver-manage/taskList/driverDetails',
    name: 'TaskDetails',
    hidden: true,
    component: () =>
      import('@/views/userCenter/driverManage/taskList/detail/driverDetails'),
  },
  {
    path: '/user-center/driver-manage/driverList/session_record_details',
    name: 'SessionRecordDetails',
    hidden: true,
    component: () =>
      import(
        '@/views/userCenter/driverManage/driverList/components/details/sessionRecordDetails'
      ),
    meta: { title: '会话记录详情' },
  },
  {
    path: '/user-center/driver-manage/callRecord',
    name: 'callRecord',
    component: () => import('@/views/userCenter/driverManage/callRecord/list'),
    meta: { title: '通话记录', keepAlive: true },
  },
]
