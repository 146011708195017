// undefined为了处理el-input-number默认值为0，置空
const state = {
  form: {
    order_vehicle_num: '', // 	订单车型编号
    price_mode_code: [],
    mileage_price: {
      pathway: undefined /** 途径点数量限制 */,
      backtracking_rate: 8 /** 返程费率 **/,
      push_template_id: '' /** 推单模板 */ /** 分段计价 */,
      price_section: [
        {
          price_type: 1 /** 计价方式：1=一口价，2=单位计价 */,
          min_value: 0 /** 最小公里 */,
          max_value: undefined /** 最大公里 */,
          price: undefined /** 一口价价格或者每公里N元 */,
        },
        {
          price_type: 2,
          min_value: undefined,
          price: undefined,
        },
      ],
    }, // 标准里程价格
    point_price: {
      charge_type: 100 /** 是否收费 100=免费,200=单位计价 */,
      through_point_price: undefined /** 每个点位的单价 */,
      through_type: 100 /** 途径点数量类型：100=不限制,200=限制数量 */,
      through_num: 0 /**  限制数量 */,
    }, // 标准点位费
    waiting_price: {
      waiting_free_time: undefined /** 免费等候时长，单位：秒  */,
      waiting_floating_time: undefined /** 容差时长，单位：秒 */,
      waiting_unit: undefined /** 等候费单位时长，单位：秒 */,
      waiting_unit_price: undefined /** 等候费单价，每N(分钟/秒)X元 */,
    }, // 标准等候价格
    // return_price: {
    //     return_service: undefined
    // }, // 标准返程服务
    // move_service: [
    // ], // 标准搬运服务
    // receipt_service: {
    //     photograph: "100=免费",
    //     mail:"100=商议定价",
    //     return_receipt:"100=免费"
    // }, // 标准回单服务
    move_service: [
      {
        serive_code: 20000001,
        charge_type: '整车搬运A（仅电梯、无大件）',
        price: undefined,
      },
      {
        serive_code: 20000002,
        charge_type: '整车搬运B（仅电梯、有大件）',
        price: undefined,
      },
      {
        serive_code: 20000003,
        charge_type: '整车搬运C（含楼梯、有大件）',
        price: undefined,
      },
      {
        serive_code: 20000004,
        charge_type: '半车搬运E（仅电梯、无大件）',
        price: undefined,
      },
      {
        serive_code: 20000005,
        charge_type: '半车搬运F（仅电梯、有大件）',
        price: undefined,
      },
      {
        serive_code: 20000006,
        charge_type: '半车搬运G（含楼梯、有大件）',
        price: undefined,
      },
    ], // 标准特殊规格
    cat_way: {
      on_time_service: true,
      booking_service: {
        start_time: undefined,
        end_time: undefined,
      },
    }, // 标准用车方式
    settlement_type: {
      pay_in_advance_service: true,
      pay_on_delivery_service: true,
    }, // 标准结算方式

    mileage_price: {
      driver_drop_price: undefined /** 起步价配置 */,
      driver_drop_distance: undefined /** 司机起步里程 */,
      driver_trigger_distance_unit_price:
        undefined /** 触发里程价（用于判断是否使用司机推荐里程价） */,
      back_tracking_rate: 8 /** 返程费率 */,
      driver_recommend_lower_distance_unit_price:
        undefined /** 司机推荐最低里程价 */,
      driver_recommend_higher_distance_unit_price:
        undefined /** 司机推荐最高里程价 */,
      push_template_id: '' /** 推单模板 */,
      pathway: undefined /** 途径点数量限制 */,
    }, //特惠里程价
    point_price: {
      charge_type: 100 /** 是否收费 100=免费,200=单位计价 */,
      through_point_price: undefined /** 每个点位的单价 */,
      through_type: 300 /** 途径点数量类型：100=不限制,200=限制数量 */,
      through_num: 0 /**  限制数量 */,
    }, // 特惠点位费
    waiting_price: {}, // 特惠等候价格
    cat_way: {}, // 特惠用车方式
    settlement_type: {}, // 特惠结算方式
    // return_price: {}, // 特惠返程服务
    move_service: [], // 特惠搬运服务
    receipt_service: {}, // 特惠回单服务
    special_special_specification: {}, // 特惠特殊规格
    backtracking_rate: {
      mileage_service: 8,
      spot_service: 8,
      wait_service: 8,
      // return_service_price: 8,
      carriage_a_service: 8,
      carriage_b_service: 8,
      carriage_c_service: 8,
      carriage_e_service: 8,
      carriage_f_service: 8,
      carriage_g_service: 8,
      // receipt_by_mail_service: 8
    }, // 特惠费率集合
    backtracking_rate: {
      mileage_service: 8,
      spot_service: 8,
      wait_service: 8,
      // return_service_price: 8,
      carriage_a_service: 8,
      carriage_b_service: 8,
      carriage_c_service: 8,
      carriage_e_service: 8,
      carriage_f_service: 8,
      carriage_g_service: 8,
      // receipt_by_mail_service: 8
    }, // 标准费率集合
  },

  optionalConfig: {
    // returnService: [
    //     {
    //         keyName: 'return_price',
    //         lowName: 'return_price',
    //         freeName: 'return_service_price',
    //         sonName: 'return_service',
    //         data: {
    //             return_service: undefined
    //         }, // 标准返程服务
    //         name: '返程服务',
    //         standard: false, // 标准
    //         reduced: false  // 特惠
    //     }
    // ],
    handlingService: [
      {
        keyName: 'move_service',
        lowName: 'move_service',
        freeName: 'carriage_a_service',
        data: {
          serive_code: 20000001,
          name: '整车搬运A（仅电梯、无大件）',
          price: undefined,
        },
        standard: false,
        reduced: false,
      },
      {
        keyName: 'move_service',
        lowName: 'move_service',
        freeName: 'carriage_b_service',
        data: {
          serive_code: 20000002,
          name: '整车搬运B（仅电梯、有大件）',
          price: undefined,
        },
        standard: false,
        reduced: false,
      },
      {
        keyName: 'move_service',
        lowName: 'move_service',
        freeName: 'carriage_c_service',
        data: {
          serive_code: 20000003,
          name: '整车搬运C（含楼梯、有大件）',
          price: undefined,
        },
        standard: false,
        reduced: false,
      },
      {
        keyName: 'move_service',
        lowName: 'move_service',
        freeName: 'carriage_e_service',
        data: {
          serive_code: 20000004,
          name: '半车搬运E（仅电梯、无大件）',
          price: undefined,
        },
        standard: false,
        reduced: false,
      },
      {
        keyName: 'move_service',
        lowName: 'move_service',
        freeName: 'carriage_f_service',
        data: {
          serive_code: 20000005,
          name: '半车搬运F（仅电梯、有大件）',
          price: undefined,
        },
        standard: false,
        reduced: false,
      },
      {
        keyName: 'move_service',
        lowName: 'move_service',
        freeName: 'carriage_g_service',
        data: {
          serive_code: 20000006,
          name: '半车搬运G（含楼梯、有大件）',
          price: undefined,
        },
        standard: false,
        reduced: false,
      },
    ],
    // receiptService: [
    //     {
    //         keyName: 'receipt_service',
    //         lowName: 'receipt_service',
    //         sonName: 'receipt_by_photo_service',
    //         data: {
    //             receipt_by_photo_service: 100
    //         },
    //         name: '拍照回单',
    //         standard: false,
    //         reduced: false
    //     },
    //     {
    //         keyName: 'receipt_service',
    //         lowName: 'receipt_service',
    //         sonName: 'receipt_by_mail_service',
    //         freeName: 'receipt_by_mail_service',
    //         data: {
    //             receipt_by_mail_service: 100
    //         },
    //         name: '邮寄回单',
    //         standard: false,
    //         reduced: false
    //     },
    //     {
    //         keyName: 'receipt_service',
    //         lowName: 'receipt_service',
    //         sonName: 'receipt_by_return_service',
    //         data: {
    //             receipt_by_return_service: 100
    //         },
    //         name: '返程交付回单',
    //         standard: false,
    //         reduced: false
    //     },
    // ],
    receiptReturnService: [
      {
        keyName: 'receipt_return_service',
        lowName: 'receipt_return_service',
        sonName: 'receipt_paper_service',
        data: {
          receipt_paper_service: true,
        },
        serive_code: '30000001',
        name: '纸质回单',
        standard: false,
        reduced: false,
      },
      {
        keyName: 'receipt_return_service',
        lowName: 'receipt_return_service',
        sonName: 'receipt_photo_service',
        data: {
          receipt_photo_service: true,
        },
        serive_code: '30000002',
        name: '回单照片',
        standard: false,
        reduced: false,
      },
    ],
    carWay: [
      {
        keyName: 'cat_way',
        lowName: 'cat_way',
        sonName: 'on_time_service',
        data: {
          on_time_service: true,
        },
        serive_code: '12000001',
        name: '即时用车',
        standard: false,
        reduced: false,
      },
      {
        keyName: 'cat_way',
        lowName: 'cat_way',
        sonName: 'booking_service',
        data: {
          booking_service: {
            start_time: undefined,
            end_time: undefined,
          },
        },
        serive_code: '12000002',
        name: '预约用车',
        standard: false,
        reduced: false,
      },
    ],
    payWay: [
      {
        keyName: 'settlement_type',
        lowName: 'settlement_type',
        sonName: 'pay_in_advance_service',
        data: {
          pay_in_advance_service: true,
        },
        serive_code: '13000001',
        name: '现付',
        standard: false,
        reduced: false,
      },
      {
        keyName: 'settlement_type',
        lowName: 'settlement_type',
        sonName: 'pay_on_delivery_service',
        data: {
          pay_on_delivery_service: true,
        },
        serive_code: '13000002',
        name: '到付',
        standard: false,
        reduced: false,
      },
      {
        keyName: 'settlement_type',
        lowName: 'settlement_type',
        sonName: 'pay_after_delivery',
        data: {
          pay_after_delivery: true,
        },
        serive_code: '13000003',
        name: '后付',
        standard: false,
        reduced: false,
      },
      {
        keyName: 'settlement_type',
        lowName: 'settlement_type',
        sonName: 'pay_online_payment',
        data: {
          pay_online_payment: true,
        },
        serive_code: '13000004',
        name: '线上支付',
        standard: false,
        reduced: false,
      },
      {
        keyName: 'settlement_type',
        lowName: 'settlement_type',
        sonName: 'pay_offline_payment',
        data: {
          pay_offline_payment: true,
        },
        serive_code: '13000005',
        name: '线下支付',
        standard: false,
        reduced: false,
      },
    ],
  },
}

const mutations = {
  SET_FORM: (state, data) => {
    // eslint-disable-next-line no-prototype-builtins
    state.form = data
  },
  INIT_FORM: (state, data) => {
    // 费率默认为8
    state.form = {
      order_vehicle_num: '', // 	订单车型编号
      price_mode_code: [],
      mileage_price: {
        driver_drop_distance: undefined /** 司机起步里程 */,
        driver_drop_price: undefined /** 起步价配置 */,
        pathway: undefined /** 途径点数量限制 */,
        backtracking_rate: 8 /** 返程费率 **/,
        push_template_id: '' /** 推单模板 */ /** 分段计价 */,
        price_section: [
          {
            price_type: 1 /** 计价方式：1=一口价，2=单位计价 */,
            min_value: 0 /** 最小公里 */,
            max_value: undefined /** 最大公里 */,
            price: undefined /** 一口价价格或者每公里N元 */,
          },
          {
            price_type: 2,
            min_value: undefined,
            price: undefined,
          },
        ],
      }, // 标准里程价格
      point_price: {
        charge_type: 100 /** 是否收费 100=免费,200=单位计价 */,
        through_point_price: undefined /** 每个点位的单价 */,
        through_type: 100 /** 途径点数量类型：100=不限制,200=限制数量 */,
        through_num: 0 /**  限制数量 */,
      }, // 标准点位费
      waiting_price: {
        waiting_free_time: undefined /** 免费等候时长，单位：秒  */,
        waiting_floating_time: undefined /** 容差时长，单位：秒 */,
        waiting_unit: undefined /** 等候费单位时长，单位：秒 */,
        waiting_unit_price: undefined /** 等候费单价，每N(分钟/秒)X元 */,
      }, // 标准等候价格
      // return_price: {
      //     return_service: undefined
      // }, // 标准返程服务
      move_service: [
        {
          serive_code: 20000001,
          name: '整车搬运A（仅电梯、无大件）',
          price: undefined,
        },
        {
          serive_code: 20000002,
          name: '整车搬运B（仅电梯、有大件）',
          price: undefined,
        },
        {
          serive_code: 20000003,
          name: '整车搬运C（含楼梯、有大件）',
          price: undefined,
        },
        {
          serive_code: 20000004,
          name: '半车搬运E（仅电梯、无大件）',
          price: undefined,
        },
        {
          serive_code: 20000005,
          name: '半车搬运F（仅电梯、有大件）',
          price: undefined,
        },
        {
          serive_code: 20000006,
          name: '半车搬运G（含楼梯、有大件）',
          price: undefined,
        },
      ], // 标准运输服务
      cat_way: {
        on_time_service: true,
        booking_service: {
          start_time: undefined,
          end_time: undefined,
        },
      }, // 标准用车方式
      settlement_type: {
        pay_in_advance_service: true,
        pay_on_delivery_service: true,
      }, // 标准结算方式
      mileage_price: {
        driver_drop_price: undefined /** 起步价配置 */,
        driver_drop_distance: undefined /** 司机起步里程 */,
        driver_trigger_distance_unit_price:
          undefined /** 触发里程价（用于判断是否使用司机推荐里程价） */,
        back_tracking_rate: 8 /** 返程费率 */,
        driver_recommend_lower_distance_unit_price:
          undefined /** 司机推荐最低里程价 */,
        driver_recommend_higher_distance_unit_price:
          undefined /** 司机推荐最高里程价 */,
        push_template_id: '' /** 推单模板 */,
        pathway: undefined /** 途径点数量限制 */,
      }, //特惠里程价
      point_price: {
        charge_type: 100 /** 是否收费 100=免费,200=单位计价 */,
        through_point_price: undefined /** 每个点位的单价 */,
        through_type: 300 /** 途径点数量类型：100=不限制,200=限制数量 */,
        through_num: 0 /**  限制数量 */,
      }, // 特惠点位费
      cat_way: { on_time_service: true }, // 特惠用车方式
      settlement_type: { pay_in_advance_service: true }, // 特惠结算方式
      waiting_price: {}, // 特惠等候价格
      backtracking_rate: {
        mileage_service: 8,
        spot_service: 8,
        wait_service: 8,
      }, // 特惠费率集合
      backtracking_rate: {
        mileage_service: 8,
        spot_service: 8,
        wait_service: 8,
        // return_service_price: 8,
        carriage_a_service: 8,
        carriage_b_service: 8,
        carriage_c_service: 8,
        carriage_e_service: 8,
        carriage_f_service: 8,
        carriage_g_service: 8,
      }, // 标准费率集合
    }
  },
  INIT_CONFIG: (state, data) => {
    state.optionalConfig = data
      ? data
      : {
          handlingService: [
            {
              keyName: 'move_service',
              lowName: 'move_service',
              freeName: 'carriage_a_service',
              data: {
                serive_code: 20000001,
                name: '整车搬运A（仅电梯、无大件）',
                price: undefined,
              },
              standard: false,
              reduced: false,
            },
            {
              keyName: 'move_service',
              lowName: 'move_service',
              freeName: 'carriage_b_service',
              data: {
                serive_code: 20000002,
                name: '整车搬运B（仅电梯、有大件）',
                price: undefined,
              },
              standard: false,
              reduced: false,
            },
            {
              keyName: 'move_service',
              lowName: 'move_service',
              freeName: 'carriage_c_service',
              data: {
                serive_code: 20000003,
                name: '整车搬运C（仅楼梯、有大件）',
                price: undefined,
              },
              standard: false,
              reduced: false,
            },
            {
              keyName: 'move_service',
              lowName: 'move_service',
              freeName: 'carriage_e_service',
              data: {
                serive_code: 20000004,
                name: '半车搬运E（仅电梯、无大件）',
                price: undefined,
              },
              standard: false,
              reduced: false,
            },
            {
              keyName: 'move_service',
              lowName: 'move_service',
              freeName: 'carriage_f_service',
              data: {
                serive_code: 20000005,
                name: '半车搬运F（仅电梯、有大件）',
                price: undefined,
              },
              standard: false,
              reduced: false,
            },
            {
              keyName: 'move_service',
              lowName: 'move_service',
              freeName: 'carriage_g_service',
              data: {
                serive_code: 20000006,
                name: '半车搬运G（含楼梯、有大件）',
                price: undefined,
              },
              standard: false,
              reduced: false,
            },
          ],
          // receiptService: [
          //     {
          //         keyName: 'receipt_service',
          //         lowName: 'receipt_service',
          //         sonName: 'receipt_by_photo_service',
          //         data: {
          //             receipt_by_photo_service: 100
          //         },
          //         name: '拍照回单',
          //         standard: false,
          //         reduced: false
          //     },
          //     {
          //         keyName: 'receipt_service',
          //         lowName: 'receipt_service',
          //         sonName: 'receipt_by_mail_service',
          //         freeName: 'receipt_by_mail_service',
          //         data: {
          //             receipt_by_mail_service: 100
          //         },
          //         name: '邮寄回单',
          //         standard: false,
          //         reduced: false
          //     },
          //     {
          //         keyName: 'receipt_service',
          //         lowName: 'receipt_service',
          //         sonName: 'receipt_by_return_service',
          //         data: {
          //             receipt_by_return_service: 100
          //         },
          //         name: '返程交付回单',
          //         standard: false,
          //         reduced: false
          //     },
          // ],
          receiptReturnService: [
            {
              keyName: 'receipt_return_service',
              lowName: 'receipt_return_service',
              sonName: 'receipt_paper_service',
              data: {
                receipt_paper_service: true,
              },
              serive_code: '30000001',
              name: '纸质回单',
              standard: false,
              reduced: false,
            },
            {
              keyName: 'receipt_return_service',
              lowName: 'receipt_return_service',
              sonName: 'receipt_photo_service',
              data: {
                receipt_photo_service: true,
              },
              serive_code: '30000002',
              name: '回单照片',
              standard: false,
              reduced: false,
            },
          ],
          carWay: [
            {
              keyName: 'cat_way',
              lowName: 'cat_way',
              sonName: 'on_time_service',
              data: {
                on_time_service: true,
              },
              serive_code: '12000001',
              name: '即时用车',
              standard: false,
              reduced: false,
            },
            {
              keyName: 'cat_way',
              lowName: 'cat_way',
              sonName: 'booking_service',
              data: {
                booking_service: {
                  start_time: undefined,
                  end_time: undefined,
                },
              },
              serive_code: '12000002',
              name: '预约用车',
              standard: false,
              reduced: false,
            },
          ],
          payWay: [
            {
              keyName: 'settlement_type',
              lowName: 'settlement_type',
              sonName: 'pay_in_advance_service',
              data: {
                pay_in_advance_service: true,
              },
              serive_code: '13000001',
              name: '现付',
              standard: false,
              reduced: false,
            },
            {
              keyName: 'settlement_type',
              lowName: 'settlement_type',
              sonName: 'pay_on_delivery_service',
              data: {
                pay_on_delivery_service: true,
              },
              serive_code: '13000002',
              name: '到付',
              standard: false,
              reduced: false,
            },
            {
              keyName: 'settlement_type',
              lowName: 'settlement_type',
              sonName: 'pay_after_delivery',
              data: {
                pay_after_delivery: true,
              },
              serive_code: '13000003',
              name: '后付',
              standard: false,
              reduced: false,
            },
            {
              keyName: 'settlement_type',
              lowName: 'settlement_type',
              sonName: 'pay_online_payment',
              data: {
                pay_online_payment: true,
              },
              serive_code: '13000004',
              name: '线上支付',
              standard: false,
              reduced: false,
            },
            {
              keyName: 'settlement_type',
              lowName: 'settlement_type',
              sonName: 'pay_offline_payment',
              data: {
                pay_offline_payment: true,
              },
              serive_code: '13000005',
              name: '线下支付',
              standard: false,
              reduced: false,
            },
          ],
        }
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
