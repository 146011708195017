export default [
  // 投诉列表
  {
    path: '/user-center/complaintManagement/complaintList',
    name: 'complaintList',
    component: () =>
      import('@/views/userCenter/complaintManagement/complaintList/index'),
  },
  // 投诉详情
  {
    path: '/user-center/complaintManagement/complaintDetail',
    name: 'complaintDetail',
    component: () =>
      import('@/views/userCenter/complaintManagement/complaintDetail/index'),
  },
]
